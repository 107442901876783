import React, { Component }  from 'react';
//import logo from './logo.svg';
import GetPrincipal from './Componentes/getPrincipal';
import GetOpcion from './Componentes/getOpcion';


import './css/accordion.scss';
import './App.css';

class App extends Component {
  state = {
    modulo : 'principal',
    url_global : "https://elasesorpublico.com/neola/ticket/index.php/",
    data : [],
    id_opcion : 0,
    opcion : '',
    is_number_option:0
  }
  setData = (data) =>{
    this.setState({
      data
    })
  }
  getOpcion = (id_opcion,opcion) =>{
    this.setState({
      id_opcion,
      modulo : 'opcion',
      opcion
    })
  }
  getOpcionT = (id_opcion,opcion) =>{
    localStorage.nombre_maquina = "actualizar"
    this.setState({
      id_opcion,
      modulo : 'opcion',
      opcion
    })
  }

  ir_home = () =>{
    this.setState({
      id_opcion : 0,
      modulo : 'principal',
      opcion : ''
    })
  }
  consultarItemAPI = () => {
    const url= this.state.url_global + "manual/getListaCategorias";
    fetch(url)
      .then(res => res.json())
      .then((data) => {
         this.setData(data)
      })
      .catch(console.log)
  }

  render() {
    const modulo = this.state.modulo;
    let despliega;
    if(modulo==='principal'){
      despliega =<React.Fragment>
                 <GetPrincipal getOpcion={this.getOpcion} data={this.state.data} url_global={this.state.url_global} />
                 </React.Fragment>
    }
    if(modulo==='opcion'){
      despliega =<React.Fragment>
                 <GetOpcion getOpcionT={this.getOpcionT} ir_home={this.ir_home} opcion={this.state.opcion} id_opcion={this.state.id_opcion} url_global={this.state.url_global} />
                 </React.Fragment>
    }
    return (
      <React.Fragment>
        {despliega}

      </React.Fragment>
    )
  }


  componentDidMount() {
    //this.actualizar_cuentas();
    this.consultarItemAPI();
    const is_number_option = localStorage.getItem('is_number_option')
    if(is_number_option){
      localStorage.removeItem('is_number_option');
      this.setState({
        id_opcion:Number(is_number_option),
        modulo : 'opcion',
        opcion:''
      })
    }
  }
}

export default App;
