import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import GetBusqueda from './../Componentes/getBusqueda';
import logo from './../img/home.png';
import {CopyToClipboard} from 'react-copy-to-clipboard';
class getOpcion extends Component {
  constructor(props){
    super(props);
    this.state = {
        data : [],
        opciones : [],
        opcion:props.opcion
    }
  }

  replaceAttributesWithAuto=(text)=> {
     
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    const elements = doc.body.getElementsByTagName('*');
  
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
  
      if (element.tagName === 'IMG') {
        element.style.maxWidth = '100%';
      }

      if (element.hasAttribute('width')) {
        element.setAttribute('width', 'auto');
      }
  
      if (element.hasAttribute('height')) {
        element.setAttribute('height', 'auto');
      }
     
      const styleValue = element.getAttribute('style');
      if (styleValue) {
        const replacedValue = styleValue.replace(/width:\s*\d+px/gi, 'width: auto');
        const replacedStyle = replacedValue.replace(/height:\s*\d+px/gi, 'height: auto');
        element.setAttribute('style', replacedStyle);
      }
    }
 

    console.log(doc.body.innerHTML)
    return doc.body.innerHTML;
  }

  renderTablePreferencia = () => {
    const data = this.state.data;
    return (
      data.map(pasos => (
        <div>
          <div className="div_titulo">
            <h1>{pasos.titulo}</h1>
          </div>
           { ReactHtmlParser(this.replaceAttributesWithAuto(pasos.paso)) }
        </div>
          ))
        )
  }
  renderTableOpciones = () => {
    const data = this.state.opciones;
    return (
      data.map(pasos => (
        <li onClick={ () => this.getOpcionT(pasos.id_opcion,pasos.opcion)}>
          {pasos.opcion}
        </li>
          ))
      )
  }

  getOpcionT = (id_opcion,opcion) =>{
    this.props.getOpcionT(id_opcion,opcion);
  }

  consultarOpcionAPI = () => {
    const url= this.props.url_global + "manual/getOpcionHTML/"+this.props.id_opcion;
    fetch(url)
      .then(res => res.json())
      .then((data) => {
         this.setState({data})
      })
      .catch(console.log)
  }
  consultarOpcionesAPI = () => {
    const url= this.props.url_global + "manual/getOpcionesCategorias/"+this.props.id_opcion;
    fetch(url)
      .then(res => res.json())
      .then((data) => {
        //console.log(data[0].titulo)
         this.setState({opciones:data,opcion:data[0].titulo})
      })
      .catch(console.log)
  }

  render () {

    return (
      <div className="grid-container_opcion">
        <div className="lbl_busqueda_opcion">
        <div className="cssversion_pw100 cssversion_contentCenter" >
          <div  style={{"width":"10%"}}>
          </div>
          <div  style={{"width":"80%","textAlign":"center"}}>
            <img  onClick={ () => this.props.ir_home()} className="img_logo_home" alt="Cargando ..." src={logo} />
            <GetBusqueda getOpcion={this.getOpcionT} url_global={this.props.url_global} />
            </div>
           <div  style={{"width":"10%"}}>
           </div>
         </div>
        </div>
        <div className="lbl_margen_izquierdo_opcion"></div>
        <div className="lbl_margen_derecho_opcion"></div>
        <div className="lbl_contenido_opcion">
          <div class="grid-container_d" style={{"width":"100%"}}>
            <div class="lbl_contenido_d" style={{"width":"100%"}}>
              <div className="div_titulo_opcion">
                {this.state.opcion}
              </div>
              {this.renderTablePreferencia()}

              <div className="lbl_footer">
                ©{(new Date().getFullYear())} Todos los Derechos Reservados Grupo Maitre & Neola
              </div>
            </div>
            <div className="lbl_opciones_d">
              <div className="fixed-sidebar-container" >
                <div className="primary-nav">
                <nav>
                  <div className="sibling-nav" >
                    <h4>
                      <div className="lbl_ayuda">Ayuda</div>
                    </h4>
                    <CopyToClipboard text={"https://manual.neola.ec/direct.php?id="+this.props.id_opcion}
                      onCopy={() => this.setState({copied: true})}>
                      <span>Compartir</span>
                    </CopyToClipboard>
                    {this.state.copied ? <span style={{color: 'red'}}> Copiado</span> : null}
                    <ul className="sibling-list">
                      {this.renderTableOpciones()}
                    </ul>
                  </div>
                </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  /*
  /**/

  componentDidUpdate(){
    if(localStorage.nombre_maquina){
      localStorage.nombre_maquina=""
      this.consultarOpcionAPI();
      this.consultarOpcionesAPI();
    }
  }
  componentDidMount() {
    //this.actualizar_cuentas();
    this.consultarOpcionAPI();
    this.consultarOpcionesAPI();
  }
}
export default getOpcion;
