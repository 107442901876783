import React, { Component } from 'react';


class getBusqueda extends Component {
  constructor(){
    super();
    this.handleChangeE = this.handleChangeE.bind(this);
    this.state = {
      busqueda: '' ,
      data :[]
    }
  }

  handleChangeE= (event) => {
    this.setState({
      busqueda: event.target.value
    });
    this.consultarOpcionAPI( event.target.value);
  }

  abrir_modulo = (id_opcion,opcion) =>{
    this.props.getOpcion(id_opcion,opcion);
    this.setState({
      busqueda: "",data :[]
    });
  }

  renderTablePreferencia = () => {
    const data = this.state.data;
    if(data.length === 0) return null;
    return (
      <div className="contenido_busqueda">
      {data.map(opcion  =>

          <div className="contenido_busqueda_children" key={opcion.id_opcion} onClick={ () => this.abrir_modulo(opcion.id_opcion,opcion.opcion)}>
            {opcion.opcion}
          </div>

        )}
      </div>
    )
  }

  consultarOpcionAPI = (value) => {
    if(value.trim()===''){
      this.setState({data:[]})
      return null;
    }
    const url= this.props.url_global + "manual/getBuscarLista/"+value;
    fetch(url)
      .then(res => res.json())
      .then((data) => {
         if(data[0].id_opcion==='0'){
            this.setState({data:[]})
         }else{
           this.setState({data})
         }
      })
      .catch(console.log)
  }

     render (){
     return (
       <div className="promoted-search__container">
          <input className="promoted-search__input" placeholder="Describe el problema."
          type="search" aria-haspopup="false" aria-autocomplete="both" dir="ltr"
          value={this.state.busqueda} onChange={(event)=>this.handleChangeE(event)} />
          <button className="promoted-search__search-button" aria-hidden="true">
            <svg className="promoted-search__search-icon" viewBox="0 0 24 24">
              <path d="M20.49 19l-5.73-5.73C15.53 12.2 16 10.91 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.41 0 2.7-.47 3.77-1.24L19 20.49 20.49 19zM5 9.5C5 7.01 7.01 5 9.5 5S14 7.01 14 9.5 11.99 14 9.5 14 5 11.99 5 9.5z"></path>
              <path d="M0 0h24v24H0V0z" fill="none"></path>
            </svg>
          </button>
          {this.renderTablePreferencia()}
        </div>
     )
   }



 }
 export default getBusqueda;
