import React, { Component } from 'react';
import Accordion from './../Componentes/Accordion';
import GetBusqueda from './../Componentes/getBusqueda';

import logo from './../img/logo.png';
import fondo from './../img/fondo.png';
class getPrincipal extends Component {

    getOpcion = (id_opcion,opcion) =>{
      this.props.getOpcion(id_opcion,opcion);
    }
    render (){
    return (

        <div className="cssversion_menu">
            <div className="cssversion_pw100 cssversion_ph100" style={{"display":"inline-table"}} >
              <div className="cssversion_pw100 cssversion_pxh400 cssversion_contentCenter" >
                <div  style={{"width":"10%"}}>
                </div>
                  <div  style={{"width":"80%","textAlign":"center"}}>
                  <img className="img_logo" alt="Cargando ..." src={logo} /><br/>
                  <div className="lbl_como">¿Cómo podemos ayudarte?</div>
                  <img className="img_fondo" alt="Cargando ..." src={fondo} />
                  <GetBusqueda url_global={this.props.url_global} getOpcion={this.getOpcion} />
                </div>
                <div  style={{"width":"10%"}}>
                </div>
              </div>
              <div className="cssversion_pw100  cssversion_contentCenter">
                <div  style={{"width":"10%"}}>
                </div>
                <div  style={{"width":"80%"}}>
                  <Accordion getOpcion={this.getOpcion} data={this.props.data} />
                </div>
                <div  style={{"width":"10%"}}>
                </div>
              </div>
              <div className="lbl_footer">
                ©{(new Date().getFullYear())} Todos los Derechos Reservados Grupo Maitre & Neola
              </div>
            </div>
        </div>
    )
  }



}
export default getPrincipal;
